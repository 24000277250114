<template>
  <div class="jh-dialog-wrap">
    <div class="jh-dialog-header">
      <h1>{{ headerTitle }}</h1>
      <div class="is-right">
        <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
      </div>
    </div>
    <div class="jh-dialog-body">
      <table class="jh-tbl-detail">
        <colgroup>
          <col width="80px">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th><label>작성자</label></th>
            <td><v-text-field type="text" class="jh-form is-trns" v-model="txtBoxUserV" disabled></v-text-field></td>
          </tr>
          <tr>
            <th><label class="is-required">제목</label></th>
            <td><v-text-field type="text" class="jh-form" v-model="txtBoxTitleV"></v-text-field></td>
          </tr>
        </tbody>
      </table>

      <div class="jh-editor is-mt-5"><ckeditor v-model="txtAreaV" :config="editorConfig"></ckeditor></div>
      
      <div class="jh-ui-header is-mt-10">
        <h2>첨부파일</h2>
        <div class="is-right">
          <v-btn class="jh-btn is-border-blue" @click="clickAddFiles">파일등록</v-btn>
          <v-btn class="jh-btn is-del" @click="clickDelFiles" >파일삭제</v-btn>
        </div>
      </div>
      <v-data-table
        class="jh-grid has-control"
        height="116"
        :headers="gridDataHeaders"
        :items="gridDataText"
        v-model="gridListSelected"
        item-key="index"
        fixed-header
        hide-default-footer
        calculate-widths
        show-select
        :itemsPerPage="-1"
      >
        <template v-slot:item.DOWNLOAD = {item}>
          <div>
            <v-btn  class="jh-btn is-sm is-fill" @click="fileDownload(item)">다운로드</v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="jh-dialog-footer" >
        <v-btn class="jh-btn is-md is-main" @click="clickBtnSave">저장</v-btn>
    </div>

    <!-- 파일 업로드 모달창 -->
    <v-dialog persistent v-model="dialogVueDropzone" v-if="dialogVueDropzone">
      <dialog-VueDropzone
        headerTitle="첨부파일 등록"
        :ASP_NEWCUST_KEY = this.dropZoneParam.ASP_NEWCUST_KEY
        :fileGroupKey    = this.dropZoneParam.fileGroupKey
        :pathType        = this.dropZoneParam.pathType
        :busiType        = this.dropZoneParam.busiType
        :acceptedFiles   = this.dropZoneParam.acceptedFiles
        :userOptions     = this.dropZoneParam.userOptions
        @hide="hideDialog('VueDropzone')"
        @submit="uploadFileResponse"
      >
      </dialog-VueDropzone>
    </v-dialog>
    <!-- 파일 업로드 모달창 -->
  </div>
</template>
<script>
  import axios from "axios";
  import api from "@/store/apiUtil";
  import {mixin} from "../../mixin/mixin";
  import vue2Dropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import { mapState, mapGetters, mapActions } from "vuex";
  import CkeditorVue from '../../components/Ckeditor.vue';
  import DialogVueDropzone from "@/components/VueDropzone";
  import DialogFileUploadPop from "@/components/FileUploadPop.vue";

  export default {
    name: "MENU_E050203P01", //name은 'MENU_' + 파일명 조합

    props: {
      param: {
        type: Object,
      },
      headerTitle: {
        type: String,
        default: "제목",
      },
    },

    components: {
      vueDropzone: vue2Dropzone,
      DialogFileUploadPop,
      DialogVueDropzone,
      //Ckeditor,
    },

    mixins:[mixin],

    data() {
      return {
        // 첨부파일, 첨부파일업로드 팝업 변수
        dialogVueDropzone: false,
        dropZoneParam    : {},

        gridDataText    : [], //첨부파일 리스트
        gridListSelected: [], //파일업로드 팝업 변수

        DialogFileUploadPop: false,
        fileInfo: {},

        // textField, textArea 변수
        brdNo       : '',
        brdRmkNo    : '',
        txtBoxBizV  : '',
        txtBoxUserV : '',
        txtBoxTitleV: '',
        txtAreaV    : '',

        flagData: {
          inputFlag : '',
          replyYn   : '',
          lvlNo     : '',
          upperBrdNo: '',
        },

        editorConfig: {
          extraPlugins : "font, colorbutton, colordialog, openlink, spacingsliders",
          width        : "100%",
          height       : "300px",
          toolbar: [
            { name: "clipboard",   items: [ "Cut", "Copy", "Paste", "-", "Undo", "Redo" ] },//, "PasteText", "PasteFromWord"
            { name: "editing" },   //, items: [ "Scayt" ]
            { name: "links",       items: [ "Link", "Unlink" ] },//, "Anchor"
            { name: "insert",      items: [ "Image", "Table", "HorizontalRule", "SpecialChar" ] },
            { name: "forms" },
            { name: "styles",      items: [ "Font", "FontSize" ] },
            { name: "styles",      items: [ "Styles", "Format" ] },
            { name: "others",      items: [ "-" ] },
            "/",
            { name: "colors",      items: [ "TextColor", "BGColor", "spacingsliders" ] },
            { name: "basicstyles", items: [ "Bold", "Italic", "Strike", "-", "RemoveFormat" ] },
            { name: "paragraph",   items: [ "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote" ] },
            { name: "document",    items: [ "Source" ] },
            { name: "tools",       items: [ "Maximize" ] },
            { name: "about",       items: [] }
          ]
        },

        footerHideTitle: "닫기",

        // 첨부파일 그리드 헤더
        gridDataHeaders: [
          { text: "No",       value: "index",   width: "50px",  align: "center", sortable: false,},
          { text: "파일명",    value: "ORIGINAL_FILENAME",  sortable: false,},
          { text: "크기",      value: "FILE_SIZE", width: "80px", align: "center", sortable: false,},
          { text: "다운로드",   value: "DOWNLOAD", width: "70px", align: "center", sortable: false,},
          { text: "다운로드수", value: "DNLOD_CNT", width: "80px", align: "center", sortable: false,},
        ],

        // etc
        ASP_CUST_KEY   : this.$store.getters["userStore/GE_USER_ROLE"].company,
        ASP_NEWCUST_KEY: "001",
        FILE_GROUP_KEY : "",
        FILE_NM        : '',
        USER_ID        : '',

        BRD_QKTY:[],
        BRD_NEWQKTY: '0',

        alertMsg: {
          selBoxBizChk : '회사구분 은(는) 필수입력입니다.',
          selBoxIdChk  : '게시판명 은(는) 필수입력입니다.',
          noCntData    : '조회된 데이터가 없습니다.',
          newWriteChk  : '조회 후 작성 가능합니다.',
          responseError: '시스템에 오류가 발생하였습니다.'
        }
      }
    },
    methods: {

      //***************************************
      //             게시글 저장
      //***************************************
      // 저장 버튼 clickEvent
      clickBtnSave() {
        if(this.txtBoxTitleV=='' || this.txtBoxTitleV==null) {
          this.common_alert("제목 은(는) 필수입력입니다.", "error");
          return;
        }
        this.getBrdRmkNo(); //rmkNo 받아오기 후 -> setSaveData
      },

      // 데이터 저장
      setSaveData() {
        let requestData = {
          headers: {},
          sendData: {}
        }

        requestData.headers["URL"]      = "/api/board/common/ntt-atchmnfl/upload";
        requestData.headers["SERVICE"]  = "board.common.ntt-atchmnfl";
        requestData.headers["METHOD"]   = "upload";
        requestData.headers["ASYNC"  ]  = "true";
        requestData.headers["CALL_BACK"]= "BoardProcessPopup.processRtn_callback";
        requestData.headers["TYPE"]     = "BIZ_SERVICE";

        requestData.sendData["ASP_NEWCUST_KEY"]= "001";
        requestData.sendData["FST_USER_NM"]    = this.$store.getters["userStore/GE_USER_ROLE"].userId;
        requestData.sendData["BRD_TIT"]        = this.restoreXSS_CKeditorDec(this.txtBoxTitleV);
        requestData.sendData["BRD_RMK"]        = this.restoreXSS_CKeditorDec(this.txtAreaV);
        requestData.sendData["BRD_ID"]         = "2";
        requestData.sendData["BRD_NO"]         = this.brdNo;
        requestData.sendData["UPPER_BRD_NO"]   = this.flagData.upperBrdNo;
        requestData.sendData["LVL_NO"]         = this.flagData.lvlNo;
        requestData.sendData["REPLE_YN"]       = this.flagData.replyYn;
        requestData.sendData["USE_YN"]         = "Y";
        requestData.sendData["BRD_RMK_NO"]     = this.brdRmkNo;
        requestData.sendData["USER_ID"]        = this.$store.getters["userStore/GE_USER_ROLE"].userId;
        requestData.sendData["PROC_ID"]        = this.$store.getters["userStore/GE_USER_ROLE"].userId;
        requestData.sendData["WRTR_DRPT_CD"]   = "x";
        requestData.sendData["ACCESS_IP"]      = this.$store.getters["userStore/GE_USER_ROLE"].cnnctIp;
        requestData.sendData["DATA_FLAG"]      = this.flagData.inputFlag;
        requestData.sendData["FILE_GROUP_KEY"] = this.FILE_GROUP_KEY;
        requestData.sendData["BRD_QUICK_TY"]   = this.BRD_NEWQKTY;

        if(requestData.sendData["BRD_RMK"].length > 65535){
          this.common_alert(`내용은 65,535Byte를 초과할 수 없습니다.\n현재 ${requestData.sendData["BRD_RMK"].length}Byte 입력하셨습니다.`, "noti");
          return;
        }

        this.clickBtnSaveThen(requestData);
      },

      // 데이터 저장 callBack
      async clickBtnSaveThen(requestData) {
        let response = await this.common_postCall(requestData);
        let resHeader = response.HEADER;
        let resData = response.DATA;

        if(resHeader.ERROR_FLAG==false) {
          this.common_alert("게시물이 등록 또는 수정되었습니다.", "sucess");
          this.popupClose();
          return;
        } else {
          this.common_alert(this.alertMsg.responseError, "error");
          return;
        }
        //this.$emit('submit');
      },

      //*********************************
      //        첨부파일 업로드
      //*********************************
      // 파일첨부 버튼 클릭
      clickAddFiles() {
        let busiType = "bbs";   //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage

        this.dropZoneParam = {
          ASP_NEWCUST_KEY  : "001",
          fileGroupKey     : this.FILE_GROUP_KEY,
          pathType         : pathType, //publics,files,images,videos,inqrytypeimage
          busiType         : busiType, //palette, phone, chat, km, bbs
          acceptedFiles    : ".png,.jpg,.gif,.jpeg,.doc,.docx,.pdf,.csv,.xls,.xlsx,.ppt,.txt,.pptx,.crt,.cer",
          userOptions :{
            parallelUploads: 10,
            //url : process.env.VUE_APP_API_URL + "api/file/"+ busiType +"/"+ pathType +"/uploads",
            //url : "http://local.hkpalette.com:6001/" + "api/file/"+ busiType +"/"+ pathType +"/uploads",
          }
        };
        this.showDialog('VueDropzone');
      },

      // 첨부파일 팝업 업로드 후
      async uploadFileResponse(data){
        this.hideDialog('VueDropzone');
        this.FILE_GROUP_KEY = data[0].fileGroupKey;

        let resList = await this.mixin_getFileList(data[0].fileGroupKey);
    
        this.setFileList(resList)
      },

      //*********************************
      //        첨부파일 다운로드
      //*********************************
      async fileDownload(item){
        let busiType = "bbs";   //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage
        await this.mixin_fileDownload(item, busiType, pathType)
      },

      //*********************************
      //          첨부파일 삭제
      //*********************************,
      clickDelFiles(){
        console.log(this.gridListSelected);
        if(this.gridListSelected.length != 0) {
          this.common_confirm('선택한 파일을 삭제하시겠습니까?', this.confirmDelYes, null, this.confirmDelNo, null);
        }
      },

      async confirmDelYes() {
        let rtnBln = await this.deleteFilesMulti(this.gridListSelected);

        if (rtnBln) {
          // 첨부파일 dataList 호출
          let resList = await this.mixin_getFileList(this.gridListSelected[0].FILE_GROUP_KEY);
          this.setFileList(resList)

          this.gridListSelected = [];     // 삭제 후 select 초기화
        }
      },

      confirmDelNo() {
        return;
      },

      //*********************************
      //        첨부파일 리스트 set
      //*********************************,
      setFileList(listData) {
        this.gridDataText = [];

        for(let i in listData) {
          let tempItem = listData[i];
          tempItem["FILE_SIZE"] = this.mixin_getFileSize(listData[i].FILE_SIZE);
          tempItem["index"] = ++i;

          this.gridDataText.push(tempItem);
        }
      },

      //*********************************************
      //    수정 Btn 으로 팝업 오픈 시 상세정보 가저오기
      //*********************************************
      async getBrdDetail() {
        let requestData = {
          headers: {},
          sendData: {}
        }

        requestData.headers["URL"]= "/api/board/detail-popup/selectNo-noChange/inqire";
        requestData.headers["SERVICE"]= "board.detail-popup.selectNo-noChange";
        requestData.headers["METHOD"]= "inqire";
        requestData.headers["ASYNC"]= false;
        requestData.headers["TYPE"]= "BIZ_SERVICE";

        requestData.sendData["BRD_ID"]= "2";
        requestData.sendData["BRD_NO"]= this.param.item.BRD_NO;
        requestData.sendData["ASP_NEWCUST_KEY"]= "001";

        let response = await this.common_postCall(requestData);

        if(response.HEADER.ERROR_FLAG == true) {
          this.common_alert(response.HEADER.ERROR_MSG, "error");
          return;
        }

        await this.getBrdDetailThen(response);

        requestData = {};
      },

      //**********************************
      //     상세정보 가져오기 callBack
      //**********************************
      async getBrdDetailThen(response) {
        let resHeader = response.HEADER;
        let resData = response.DATA;

        if(resHeader.DATA_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          return;
        }
        this.txtBoxbizV   = "001";
        this.txtBoxUserV  = this.param.item.FST_USER_NM;
        this.txtBoxTitleV = this.restoreXSS_CKeditor(resData.at().BRD_TIT);
        this.txtAreaV     = this.restoreXSS_CKeditor(resData.at().BRD_RMK);

        this.FILE_GROUP_KEY = resData[0].FILE_GROUP_KEY;
        let resList = await this.mixin_getFileList(resData[0].FILE_GROUP_KEY);
        this.setFileList(resList)
      },

      //***********************************************
      //    신규, 답변 Btn 으로 팝업창 오픈시 brdNo 받기
      //***********************************************
      async getBrdNo() {
        let requestData = {
          headers : {},
          sendData: {}
        };

        requestData.headers["URL"]     = "/api/innb/common/generate-unique-no/inqry";
        requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
        requestData.headers["METHOD"]  = "inqry";
        requestData.headers["ASYNC"]   = "false";
        requestData.headers["TYPE"]    = "BIZ_SERVICE";

        requestData.sendData["BIZ_CASE"] = "BRD";

        let response = await this.common_postCall(requestData);
        if(response.HEADER.DATA_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          this.popupClose();
          return;
        }

        this.brdNo = response.DATA.at(0).RET_VAL;
      },

      //***************************************
      //       게시글 저장용 brdRmkNo 받기
      //***************************************
      async getBrdRmkNo() {
        let requestData = {
          headers : {},
          sendData: {}
        };

        requestData.headers["URL"]     = "/api/innb/common/generate-unique-no/inqry";
        requestData.headers["SERVICE"] = "innb.common.generate-unique-no";
        requestData.headers["METHOD"]  = "inqry";
        requestData.headers["ASYNC"]   = "false";
        requestData.headers["TYPE"]    = "BIZ_SERVICE";

        requestData.sendData["BIZ_CASE"] = "TWB";

        let response = await this.common_postCall(requestData);
        if(response.HEADER.DATA_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          return;
        }

        this.brdRmkNo = response.DATA.at(0).RET_VAL;
        await this.setSaveData();
      },

      //*************************************
      //        팝업창 오픈 시 유형 체크
      //*************************************
      checkFlag(flag) {
        switch (flag) {
          case 'new':
            this.flagData.inputFlag  = 'I';
            this.flagData.replyYn    = '0';
            this.flagData.lvlNo      = '-1';
            this.flagData.upperBrdNo = '-1';
            this.getBrdNo();
            break;

          case 'modify':
            this.flagData.inputFlag  = 'U';
            this.flagData.replyYn    = '0';
            this.flagData.lvlNo      = '-1';
            this.flagData.upperBrdNo = '-1';
            this.brdNo = this.param.item.BRD_NO;
            this.getBrdDetail();
            break;

          case 'reply':
            this.flagData.inputFlag  = 'I';
            this.flagData.replyYn    = '1';
            this.flagData.lvlNo      = Number(this.param.item.LVL_NO) + 1;
            this.flagData.upperBrdNo = this.param.item.BRD_NO;
            this.getBrdNo();
            break;

          default:
            break;
        }
      },

      //*********************************
      //           etc method
      //*********************************,
      showDialog(menu) {
        this[`dialog${menu}`] = true;
      },

      hideDialog(menu) {
        this[`dialog${menu}`] = false;
      },

      popupClose() {
        this.$emit('hide')
      },

      showAlert(msg) {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: msg,
          iconClass: "svg-error-lg",
          type: "default",
        });
      },

      initFile(){
        this.FILE_NM = '';
        this.fileInfo.file = '';
      },

      setFile() {
        this.FILE_NM = this.fileInfo.file;
      },
      
    },

    created() {
    },

    async mounted() {
      this.txtBoxBizV      = this.param.bizNm;
      this.txtBoxUserV     = this.user_name +"("+ this.user_id +")";
      this.ASP_NEWCUST_KEY = "001";
      this.USER_ID         = this.param.userId;

      this.checkFlag(this.param.flag);

      let codeName    = ["BRD001"];
      let common_code = await this.mixin_common_code_get_all(codeName);
      this.BRD_QKTY   = [...this.BRD_QKTY, ...common_code[0].group_value];
    },

    computed: {
      ...mapGetters({
        user_name     : "userStore/GE_USER_NAME",
        user_id       : "userStore/GE_USER_ID",
        atrtGroupNm   : "userStore/GE_USER_AUTHOR_GROUP_NM",
        ATTR_DIV_NM_A : "userStore/GE_USER_ATTR_DIV_NM_A",
        ATTR_DIV_NM_B : "userStore/GE_USER_ATTR_DIV_NM_B",
      }),
    },
  };

</script>

<style></style>
